import React from "react";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import Particles from 'react-tsparticles';
import Footer from "../component/footer/Footer";
import {FiChevronUp} from "react-icons/fi";
import ScrollToTop from "react-scroll-up";

import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import TabTwo from "../elements/tab/TabTwo";

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Welcome to my World',
        description: 'Coming Soon....',
        buttonText: '',
        buttonLink: ''
    }
]
const PortfolioLanding = () => {
    const PostList = BlogContent.slice(0 , 3);
    let title = 'About Me',
        description = 'As a software engineer, I have a passion for using my technical expertise to build innovative and effective solutions for complex problems. With over 3 years of experience in the industry, I have a strong background in computer science and a proven track record of successfully designing, developing, and deploying high-quality software. I am highly skilled in a range of programming languages, including Java, Python, and C++, and I have experience working on a wide range of projects, from web applications to mobile apps and desktop software. I am always looking for new challenges and opportunities to further advance my skills and expand my expertise.';

    return (
        <div className="active-dark">
            <div className="frame-layout__particles">
            <Helmet pageTitle="Portfolio" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>
            {/* Start Slider Area   */}
            <div id="home" className="fix">
            <Particles className="particle"  
                            options={{
                                style:{
                                    position: "absolute"
                                },
                                fpsLimit: 100,
                                interactivity: {
                                detectsOn: "canvas",
                                events: {
                                    onClick: {
                                    enable: true,
                                    mode: "push",
                                    },
                                    onHover: {
                                    enable: true,
                                    mode: "repulse",
                                    },
                                    resize: true,
                                },
                                modes: {
                                    bubble: {
                                        distance: 100,
                                        duration: 2,
                                        opacity: 0.8,
                                        size: 10,
                                        color: "#888",
                                    },
                                    push: {
                                    quantity: 4,
                                    },
                                    repulse: {
                                        distance: 100,
                                        duration: 0.4,
                                        color: "#888",
                                    },
                                },
                                },
                                particles: {
                                color: {
                                    value: "#888",
                                },
                                links: {
                                    color: "#888",
                                    distance: 150,
                                    enable: true,
                                    opacity: 0.5,
                                    width: 1,
                                },
                                collisions: {
                                    enable: true,
                                },
                                move: {
                                    direction: "none",
                                    enable: true,
                                    outMode: "bounce",
                                    random: false,
                                    speed: 3,
                                    straight: false,
                                   
                                },
                                number: {
                                    density: {
                                        enable: true,
                                        value_area: 2000,
                                    },
                                    value: 80,
                                },
                                opacity: {
                                    value: 0.5,
                                },
                                shape: {
                                    type: "circle",
                                },
                                size: {
                                    random: true,
                                    value: 5,
                                },
                                },
                                detectRetina: true,
                            }}
                        />
                    </div>
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide designer-portfolio slider-style-3 slider-paralax d-flex align-items-center justify-content-center rn-slider-height bg_image" key={index}>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-4">
                                        <div className="designer-thumbnail">
                                            <img src="/assets/images/about/jewel_shamim_photo.png" alt="Slider Images"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title">Hi, I’m Jewel Shamim <br/>
                                            
                                            <TextLoop>
                                                <span> Full-Stack Developer.</span>
                                                <span> Mobile Apps Developer.</span>
                                                <span> UI/UX Designer.</span>
                                            </TextLoop>{""}
                                            </h1>
                                            <h2>based in Canada.</h2>
                                            {value.description ? <span className="">{value.description}</span> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/jewel_shamim_photo.png" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Area */}
            {/* Start Blog Area */}
            {/* <div className="rn-blog-area pt--120 bg_color--1 mb-dec--30">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="section-title text-left">
                                <h2>Latest Blogs</h2>
                                <p>Stay up-to-date with our latest blog posts and industry insights.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                <a className="btn-transparent rn-btn-dark" href="/blog"><span className="text">View All News</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--60 mt_sm--40">
                        {PostList.map((value , i ) => (
                            <div className="col-lg-4 col-md-6 col-12" key={i}>
                                <div className="blog blog-style--1">
                                    <div className="thumbnail">
                                        <a href="/blog-details">
                                            <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content">
                                        <p className="blogtype">{value.category}</p>
                                        <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                        <div className="blog-btn">
                                            <a className="rn-btn text-white" href="/blog-details">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div> */}
            {/* End Blog Area */}
            {/* Start Brand Area */}
            {/* <div className="rn-brand-area brand-separation pb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <BrandTwo />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* End Brand Area */}
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            <Footer />


        </div>
    )
}

export default PortfolioLanding;
