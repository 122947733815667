import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";


const PortfolioList = [
    {
        images: '9',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        images: '8',
        category: 'Html',
        title: 'Getting tickets to the big show'
    },
    {
        images: '7',
        category: 'Graphic',
        title: 'Getting tickets to the big show'
    },
    {
        images: '4',
        category: 'Developer',
        title: 'The design is Thinking'
    },
    {
        images: '5',
        category: 'Freelancer',
        title: 'The portfolio For Frelancer'
    },
    {
        images: '6',
        category: 'Freelancer',
        title: 'You can see your Portfolio'
    },
    {
        images: '7',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
    {
        images: '1',
        category: 'Freelancer',
        title: 'The new Thinking for Design'
    },
    {
        images: '2',
        category: 'Freelancer',
        title: 'The new Thinking for Design'
    },
    {
        images: '3',
        category: 'Freelancer',
        title: 'Getting tickets to the big show'
    },
    {
        images: '8',
        category: 'Freelancer',
        title: 'You can see your Portfolio'
    },
    {
        images: '9',
        category: 'Freelancer',
        title: 'Getting tickets to the big show'
    },
]

const Portfolio = () => {
    return (
        <>
            <PageHelmet pageTitle='Counters' />

            {/* Start Header Area  */}
            {/* <HeaderThree headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            <HeaderThree homeLink="/" logo="symbol-dark" color="color-white"/>
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Portfolio'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Portfolio Area  */}
                <div className="portfolio-area pt--50 pb--120 bg_color--1">
                    <div className="creative-portfolio-wrapper plr--10">
                        <div className="container">
                        <div className="row row--5">
                            {PortfolioList.map((value , i ) => (
                                <div className="col-lg-3 col-md-6 col-12" key={i}>
                                    <div className="portfolio-style--3">
                                        <div className="thumbnail">
                                            <a href="/portfolio-details">
                                                <img className="w-100" src={`/assets/images/portfolio/portfolio-${value.images}.jpg`} alt="Portfolio Images"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="portfoliotype">{value.category}</p>
                                            <h4 className="title"><a href="/portfolio-details">{value.title}</a></h4>
                                            <div className="portfolio-btn">
                                                <a className="rn-btn text-white" href="/portfolio-details">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 text-center">
                                    <a className="rn-button-style--2 btn-solid" href="/portfolio"><span>View More Project</span></a>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area  */} 

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>
        
    )
}

export default Portfolio;